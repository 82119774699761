import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import Pricing from "../../components/section/Pricing/pricing"
import CallToAction from "../../components/section/CallToAction/callToAction"

const EstudioFotograficoPage = () => {
    const data = useStaticQuery(graphql`
    query {
      estudioFotrograficoImage1: file(relativePath: { eq: "images/sections/productora-audiovisual/estudio-fotografico/estudio-fotografico-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      estudioFotrograficoImage2: file(relativePath: { eq: "images/sections/productora-audiovisual/estudio-fotografico/que-tipo-fotos-hacemos.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Estudio Fotográfico"
                description="Estudio fotográfico en Barcelona experto en fotografía de personas, productos y bodegones de diferentes sectores."
            />

            <BannerPage
                title="Fotografía de producto profesional"
                text="Realización de contenido multimedia y audiovisual"
                color="yellow"
            />

            <SectionTextImage
                title1="¡Tu imagen dice "
                title2="mucho de ti!"
                text={<Fragment>
                    Toda empresa necesita plasmar de forma gráfica aquello que ofrece. Como bien hemos dicho, una imagen vale más que mil palabras. Es por eso, que la <strong>fotografía de producto es un elemento muy importante para mostrar a tus clientes qué productos puedes ofrecer</strong>. Mediante la fotografía, es posible determinar la calidad del producto y sus principales características.
                </Fragment>}
                image={data.estudioFotrograficoImage1.childImageSharp.fluid}
                imageAlt="Reportajes fotográficos Barcelona - Agencia Productora Audiovisual"
                color="yellow"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="¿Qué tipo de fotos "
                title2="hacemos?"
                text={<Fragment>
                    En IBX llevamos a cabo:<br /><br />
                    <ul className="text-left">
                        <li><strong>Fotografía de diferentes tipologías</strong>: personas, productos, bodegones,etc.</li>
                        <li><strong>Fotografía para diversos sectores</strong>: moda, belleza, salud, alimentación, industria, entre otros.</li>
                        <li><strong>Fotografía para ser aplicada en varios formatos, resoluciones y  soportes</strong> tanto físicos como digitales.</li>
                    </ul>
                </Fragment>}
                image={data.estudioFotrograficoImage2.childImageSharp.fluid}
                imageAlt="Conultores SEM - Agencia Posicionamiento SEM"
                color="yellow"
                containerTextRight={true}
            />

            <hr className="space-2-bottom space-3-bottom--lg" />

            <Pricing
                color="yellow"
                silverText="Ideal para reportajes de fotos cortos"
                silverPrice="180"
                silverPriceCurrency="€"
                silverFeatures={["Reportaje fotrográfico de producto en estudio", "Jornada de 2 horas", "Retoque fotográfico", "Entrega del material en baja y alta resolución"]}
                silverException={[]}
                goldText="Ideal para reportajes de fotos medianos"
                goldPrice="250"
                goldPriceCurrency="€"
                goldFeatures={["Reportaje fotrográfico de producto en estudio", "Jornada de 4 horas", "Retoque fotográfico", "Entrega del material en baja y alta resolución"]}
                goldException={[]}
                diamondText="Ideal para reportajes de fotos completos"
                diamondPrice="350"
                diamondPriceCurrency="€"
                diamondFeatures={["Reportaje fotrográfico de producto en estudio", "Jornada de 8 horas", "Retoque fotográfico", "Entrega del material en baja y alta resolución"]}
                diamondException={[]}
            />

            <CallToAction
                color="yellow"
                title="¿Te ayudamos a dar una buena imagen de tus productos?"
                text={<Fragment>
                    ¡Somos el <strong>estudio de fotografía en Barcelona</strong> que estás buscando!
                </Fragment>}
                button="¿Fotografiamos tus productos?"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default EstudioFotograficoPage
